import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

export default function IndexPage({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { allMarkdownRemark: { edges } } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = edges[0].node
  return (
    <>
      <SEO title="Home" />
      <h1 className="phonemode letterhead"><span>{frontmatter.title}</span></h1>
      <div
        className="letter-content"
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {frontmatter: { draft: { ne: true } }},
      limit: 1,
      sort: { order: DESC, fields: [frontmatter___dateUpdated, frontmatter___datePublished, frontmatter___title] },
    ) {
      edges {
        node {
          html
          fields {
            path
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
